
import { defineComponent, ref } from "vue"
import { ElForm } from "element-plus"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import { Account } from "@/core/models/Account"
import { useRouter } from "vue-router"
import { User } from "@/core/models/User"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  name: "sign-up",
  setup() {
    const store = useStore()
    const router = useRouter()
    type FormInstance = InstanceType<typeof ElForm>
    var agreeTerms = ref(false)
    const formSize = ref("")
    const ruleFormRef = ref<FormInstance>()
    const accountData = ref<Account>({ owner: {} as User } as Account)

    const rules = ref({
      title: getRule(RuleTypes.TEXT50, "Hesap Adı"),
      "owner.userName": getRule(RuleTypes.TEXT50, "Kullanıcı Adı"),
      "owner.firstName": getRule(RuleTypes.TEXT50, "Ad"),
      "owner.lastName": getRule(RuleTypes.TEXT50, "Soyad"),
      "owner.email": getRule(RuleTypes.MAIL),
      "owner.tcId": getRule(RuleTypes.TCID),
      "owner.birthDate": getRule(RuleTypes.DATE, "Doğum Tarihi"),
    })

    function submitForm(formEl: FormInstance | undefined) {
      store.dispatch(Actions.LOGOUT)
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await store.dispatch(Actions.REGISTER, accountData.value)
          router.push({ name: "sign-in" })
        }
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }

    return {
      formSize,
      ruleFormRef,
      accountData,
      agreeTerms,
      resetForm,
      submitForm,
      rules,
    }
  },
})
